import React from 'react';
import img from '../images/bg.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Intro = () => {
  return (
    <>
      <div
        className='m-auto max-w-6xl xl:max-w-full p-2 md:p-12 h-5/6'
        id='about'
        style={{
          background: 'linear-gradient(to bottom, #3b065e, #3b065e)',
          color: 'white',
        }}
      >
        <h2 className='my-2 text-center text-3xl text-white uppercase font-bold'>
          À propos
        </h2>
        <div className='flex justify-center'>
          <div className='w-24 border-b-4 border-white mb-8'></div>
        </div>
        <div
          className='flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left'
          data-aos='fade-up'
        >
          <div className='lg:w-1/2 flex flex-col lg:mx-4 justify-center '>
            <img alt='card img' className='rounded-t float-right' src={img} />
          </div>
          <div
            className='flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8'
            data-aos='zoom-in'
            data-aos-delay='500'
          >
            <h3 className='text-3xl  text-white font-bold'>
              Nous développons des applications web et mobiles sur mesure de
              haute qualité pour des organisations, des institutions et des PME.
            </h3>
            <div>
              <p className='my-3 text-xl text-[#c7bbd5] font-semibold'>
                Notre équipe est très compétente dans le développement de
                logiciels et est prête à vous aider à développer les
                applications de votre choix.
              </p>
            </div>

            <div>
              <p className='my-3 text-xl text-[#c7bcd5] font-semibold'>
                Nous assumons la responsabilité de construire des solutions
                logicielles personnalisées qui répondent à l'automatisation de
                vos processus d'entreprise et améliorent l'efficacité.
              </p>
            </div>
            <HashLink
              to='/contact'
              className='text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group'
            >
              Contact us
              <svg
                className='w-4 h-4 ml-1 group-hover: translate-x-2'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
