import React from "react";
import {
  BeatLoader,
  ClimbingBoxLoader,
  ClipLoader,
  ClockLoader,
  HashLoader,
  MoonLoader,
  PropagateLoader,
  RingLoader,
  SkewLoader,
  SquareLoader,
} from "react-spinners";

const LoaderComponent = () => {
  return (
    <div
      style={{
        width: "100vw", // 100% de la largeur de l'écran
        height: "100vh", // 100% de la hauteur de l'écran
        margin: "auto",
        backgroundColor: "#3b065e",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HashLoader color="#306cce" loading={true} size={60} />
    </div>
  );
};

export default LoaderComponent;
