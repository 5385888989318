import React from "react";
import kws from "../images/clients/kws.png";
import geps from "../images/clients/geps.png";
import protergia from "../images/clients/protergia.png";
import team1 from "../images/clients/team-1.jpg";
import team2 from "../images/clients/team-2.jpg";
import team3 from "../images/clients/team-3.jpg";
import mika from "../images/clients/mika.jpeg";
import namana1 from "../images/clients/riba.jpg";
import Mamitiana_Faneva from "../images/clients/Mamitianafaneva.jpg";
import Andonianina_Baliaka from "../images/clients/andoniainabaliaka.jpg";
import Jerry_Alex from "../images/clients/Jerryalex.jpg";
import onjamy from "../images/clients/onjamampita.JPEG";

const clientImage = {
  height: "10rem",
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return (
    <>
      <div
        className="my-4 py-4 xl:max-w-full"
        id="clients"
        style={{
          background: "linear-gradient(to bottom, #3b065e, #3b065e)",
          color: "white",
        }}
      >
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">
            Nos équipes
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-white"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#c7bcd5]">
            Rencontré nos équipes.
          </h2>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="flex justify-center flex-wrap gap-5">
            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={mika}
                />

                <h2 className="font-semibold my-4 text-2xl text-center">
                  Mika RAHELISON
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Chef de projet
                </h4>
              </div>
            </div>
            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={Andonianina_Baliaka}
                />

                <h2 className="font-semibold my-4 text-2xl text-center">
                  Andonianina Baliaka
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Chargée de Communication et Relation clientèle{" "}
                </h4>
              </div>
            </div>

            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={namana1}
                />

                <h2 className="font-semibold my-4 text-2xl text-center">
                  Serginho Riba
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Développeur Fullstack
                </h4>
              </div>
            </div>
            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={Jerry_Alex}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Jerry Alex
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Développeur Backend Java
                </h4>
              </div>
            </div>
            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={Mamitiana_Faneva}
                />

                <h2 className="font-semibold my-4 text-2xl text-center">
                  Faneva Mamitiana
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Développeur Mobile
                </h4>
              </div>
            </div>
            <div
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group"
              style={{ width: "20rem", height: "30rem", fontSize: "5%" }}
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[0.9] transition duration-1000 ease-in-out"
                  src={onjamy}
                />

                <h2 className="font-semibold my-4 text-2xl text-center">
                  Onja Mampita
                </h2>
                <h4 className="font-semibold my-4 text-xl text-center">
                  Développeur Fullstack MERN et Design UX/UI
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
