import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import DemoProduct from "./pages/DemoProduct";

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import Portfolio from "./components/Portfolio";
import LoaderPage from "./components/LoaderPage";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });

    // Simulate a delay for your hero page animation to complete
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout as needed
  }, []);

  const handleFinishLoading = () => {
    setLoading(false);
  };
  useDocTitle("miketrika");

  return (
    <>
      {loading ? (
        <LoaderPage onFinishLoading={handleFinishLoading} />
      ) : (
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/portfolio" element={<Portfolio />} />
            </Routes>
          </ScrollToTop>
        </Router>
      )}
    </>
  );
}

export default App;
