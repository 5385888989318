import React from "react";
import ksty from "../images/ksty.png";
import chezsoi from "../images/chezsoi.png";
import mihofana from "../images/mihofana.png";
import augure from "../images/augure.png";
import augureagent from "../images/augureagent.png";
import NavBar from "../components/Navbar/NavBar";
import amdtrade from "../images/amdtrade.png";
import agogolines from "../images/agogolines.png";
import Recycling from "../images/Recycling.png";
import manjifa from "../images/manjifa.png";

const Portfolio = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        className="my-4 py-4 xl:max-w-full"
        id="portfolio"
        style={{
          background: "linear-gradient(to bottom, #3b065e, #3b065e)",
          color: "white",
        }}
      >
        <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">
          Portfolio
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-white mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={mihofana}
                />

                <p className="text-md font-medium mt-4">
                  Mihofana.com, la plateforme novatrice de formation en ligne,
                  révolutionne le monde de l'apprentissage en offrant aux
                  formateurs une opportunité exceptionnelle de partager leur
                  expertise avec le monde entier. Conçue pour simplifier le
                  processus de création, de gestion et de vente de formations,
                  Mihofana.com propose une interface conviviale et des
                  fonctionnalités puissantes.
                </p>

                <a
                  href="https://mihofana.com/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={augure}
                />

                <p className="text-md font-medium mt-4">
                  AUGURE, ou Application Unique pour la Gestion Uniforme des
                  Ressources humaines de l'État, représente une avancée
                  significative dans la modernisation et l'efficacité de la
                  gestion des ressources humaines gouvernementales, y compris à
                  Madagascar. Cette plateforme innovante centralise et simplifie
                  l'ensemble du processus de gestion des ressources humaines,
                  offrant une solution intégrée pour les diverses tâches liées à
                  la gestion du personnel.
                </p>

                <a
                  href="https://sysinfo.mef.gov.mg:9733/augure.gestion.administrative/#/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={augureagent}
                />

                <p className="text-md font-medium mt-4">
                  AUGURE Compte Agent est une application révolutionnaire conçue
                  spécifiquement pour les agents de l'État malagasy, offrant une
                  plateforme complète de suivi de carrière et de gestion des
                  informations liées à la solde. Cette application innovante
                  vise à simplifier et à optimiser la gestion administrative des
                  fonctionnaires en fournissant un accès convivial à toutes les
                  données cruciales liées à leur carrière et à leur
                  rémunération.
                </p>

                <a
                  href="https://augure.mef.gov.mg/#/compte-agent"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={amdtrade}
                />

                <p className="text-md font-medium mt-4">
                  Découvrez www.amtrading.mg, votre solution complète pour
                  simplifier l'importation de divers articles de quincaillerie.
                  Notre application a été spécialement conçue pour répondre aux
                  besoins de nos clients engagés dans le secteur de la
                  quincaillerie, offrant une plateforme efficace et intuitive
                  pour gérer tous les aspects de l'approvisionnement.
                </p>

                <a
                  href="https://www.amtrading.mg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={agogolines}
                />

                <p className="text-md font-medium mt-4">
                  Bienvenue dans le futur du voyage. Vous êtes à quelques clics
                  d’une promenade à votre porte. Ou, si vous préférez conduire,
                  le voyage le moins cher que vous ayez jamais fait. Oubliez de
                  faire glisser vos bagages jusqu'à l'arrêt de bus. Oubliez les
                  transferts. Oubliez de casser votre tirelire pour un week-end.
                  Avec nous, vous vous connectez simplement avec quelqu'un de
                  proche qui se passe votre chemin.
                </p>

                <a
                  href="https://play.google.com/store/apps/details?id=com.appagogolines"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={Recycling}
                />

                <p className="text-md font-medium mt-4">
                  Notre entreprise est dédiée à la protection de l’environnement
                  en offrant des solutions de recyclage pour les entreprises et
                  les particuliers. Nous croyons que chacun peut contribuer à la
                  préservation de notre planète en réduisant les déchets et en
                  utilisant de manière responsable les ressources naturelles.
                </p>

                <a
                  href="https://play.google.com/store/apps/details?id=com.swiss.recycling.metals&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={ksty}
                />

                <p className="text-md font-medium mt-4">
                  Découvrez l'univers exquis de la glacerie avec l'application
                  KSTI, une vitrine numérique qui célèbre le talent exceptionnel
                  de notre client. Plongez dans un monde où l'art de la glace
                  devient une véritable œuvre d'art. Notre application offre une
                  expérience immersive, vous invitant à explorer les créations
                  uniques et délicieuses de notre talentueux maître glacier.
                </p>
                <a
                  href="https://miketrika.com/ksti/accueil"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={chezsoi}
                />

                <p className="text-md font-medium mt-4">
                  Chez-Soi met le pouvoir entre vos mains, vous permettant de
                  parcourir une vaste gamme de propriétés à vendre ou à louer,
                  le tout dans une interface conviviale et intuitive. Notre
                  application utilise une technologie de pointe pour vous
                  fournir des recommandations personnalisées en fonction de vos
                  préférences et critères spécifiques.
                </p>

                <a
                  href="https://miketrika.com/chez-soi/accueil"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={manjifa}
                />

                <p className="text-md font-medium mt-4">
                  Découvrez Manjifa, l'avenir du shopping en ligne ! Avec une
                  interface conviviale et intuitive, notre application
                  révolutionne votre expérience d'achat en vous offrant un accès
                  facile à une gamme diversifiée de produits exceptionnels.
                  Parcourez notre catalogue étendu comprenant des articles de
                  mode, des gadgets high-tech, des produits de beauté et bien
                  plus encore.
                </p>

                <a
                  href="https://miketrika.com/manjifa/accueil"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                >
                  voir
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
