import React from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";

const handleSmoothScroll = (event, target) => {
  event.preventDefault();
  const targetElement = document.querySelector(target);

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: "smooth",
    });
  }
};
const Home = () => {
  return (
    <>
      <Hero />
      <Intro id="about" />
      <Services />
      <Portfolio />
      {/* <Clients /> */}
      <Cta />
      <Footer handleSmoothScroll={handleSmoothScroll} />
    </>
  );
};

export default Home;
