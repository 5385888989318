// LoaderPage.js
import React, { useEffect, useState } from "react";
import Loader from "./loader"; // Assurez-vous d'avoir un composant de chargement approprié

const LoaderPage = ({ onFinishLoading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinishLoading(); // Appelé lorsque le chargement est terminé
    }, 3000); // Simule un chargement de 3 secondes (remplacez-le par votre logique de chargement réelle)

    return () => clearTimeout(timer);
  }, [onFinishLoading]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
    >
      <Loader />
    </div>
  );
};

export default LoaderPage;
