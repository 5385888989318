import React, { useState, useRef } from "react";

import { HashLink } from "react-router-hash-link";

const NavLinks = ({ onClick }) => {
  const handleSmoothScroll = (to) => {
    onClick(); // Ferme le menu déroulant sur mobile
    const element = document.getElementById(to);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        smooth
        to="/"
        onClick={() => handleSmoothScroll("accueil")}
      >
        Accueil
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        smooth
        to="/#about"
        onClick={() => handleSmoothScroll("about")}
      >
        À propos
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        smooth
        to="/#services"
        onClick={() => handleSmoothScroll("services")}
      >
        Services
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        smooth
        to="/#portfolio"
        onClick={() => handleSmoothScroll("portfolio")}
      >
        Portfolio
      </HashLink>
      {/* <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        smooth
        to="/#clients"
        onClick={() => handleSmoothScroll("clients")}
      >
        Équipes
      </HashLink> */}
      <HashLink
        className="px-4 font-extrabold text-white hover:text-[#2ec1e8]"
        to="/contact"
      >
        Contactez-nous
      </HashLink>
      <HashLink
        style={{
          color: "white",
          backgroundColor: "#2ec1e8",
          ":hover": { backgroundColor: "#2ec1e8" },
        }}
        className="inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        to="/portfolio"
      >
        Notre travaille
      </HashLink>
    </>
  );
};

export default NavLinks;
